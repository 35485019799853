.custom-dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-list {
    position: absolute;
    top: 100%; /* Place the dropdown below the button */
    left: 0;
    z-index: 1;
    border: 1px solid #ccc;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .dropdown-list li {
    padding: 8px;
    cursor: pointer;
  }
  
  .dropdown-list li:hover {
    background-color: #f0f0f0;
  }  