/* TourPackageCard.css */

/* Container for the tour package card */
.tour-package-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 100%;
  max-width: 30%; /* You may want to change this value */
  cursor: pointer;
  transition: transform 0.3s;
  margin: 4px;
}

/* Add a hover effect to scale the card on hover */
.tour-package-card:hover {
  transform: scale(1.05);
}

/* Container for the package image */
.image-container {
  overflow: hidden;
  flex: 1;
}

/* Styling for the package image */
.package-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Container for package details */
.text-container {
  flex: 1;
  padding: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

/* Styling for the package title */
.package-title {
  font-size: 1.5rem;
  color: #314787;
  font-weight: bold;
  margin: 0;
}

/* Styling for the package description */
.package-description {
  font-size: 1rem;
  color: #666;
  text-align: left;
}

/* Styling for the "Book Now" button */
.book-now-button {
  background-color: #007BFF;
  color: #fff;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  margin-top: 1rem;
}

/* Add a hover effect to the "Book Now" button */
.book-now-button:hover {
  background-color: #0056b3;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .tour-package-card {
    max-width: 100%; /* Adjust as needed for smaller screens */
  }
}
/* Your CSS file */
.maincard-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.tour-package-card {
  width: calc(25% - 20px); /* Set to 25% for four cards in a row */
  margin: 10px;

}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .tour-package-card {
    width: calc(100% - 20px);
  }
}
