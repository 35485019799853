.card {
  position: relative;
  bottom: 40px;
  width: 350px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 40px;
  margin-right: 20px;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
  text-align: center;
}

.card h1 {
  font-size: 20px;
  margin: 10px 0;
}

.card p {
  font-size: 16px;
  color: #333;
}


@media (max-width: 768px) {
  /* Apply different styles for small viewports */
  .card {
    margin-bottom: 20px;
    position: static;
    width: 100vw; /* Make the card full width on small screens */
    box-shadow: none; /* Remove the box shadow on small screens */
  }
}

